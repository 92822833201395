import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import getCroppedImg from "../../utils/cropImage";
import Button from "@mui/material/Button";
import SettingsBackupRestoreSharpIcon from "@mui/icons-material/SettingsBackupRestoreSharp";

const ImageCropper = ({ imageSrc, imageName, onClose, onSave }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [rotation, setRotation] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(1 / 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  React.useEffect(() => {
    // Load the image and get its dimensions
    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
        const width = img.width;
        const height = img.height;
        setImageDimensions({ width, height });

        // Calculate and round the aspect ratio to 1 decimal place
        const ratio = parseFloat((width / height).toFixed(1));
        console.log("ratio", ratio);
        setAspectRatio(ratio);
        setIsImageLoading(false)
      };
    }
  }, [imageSrc]);

  const showCroppedImage = useCallback(async () => {

    setIsLoading(true);
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        flipHorizontal,
        flipVertical
      );
      onSave(croppedImage);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [croppedAreaPixels, rotation]);

  const handleFlipHorizontal = () => {
    setFlipHorizontal(!flipHorizontal);
  };

  const handleFlipVertical = () => {
    setFlipVertical(!flipVertical);
  };

  return (
    <div className="flex flex-col h-full ">
      <Backdrop
        sx={{
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <span className="text-catelina-blue text-sm font-medium">
        {imageName}
      </span>

      <div className="flex flex-col md:flex-row w-full h-full">
        <div
          id="image-cropper-v2"
          className="relative w-full md:w-9/12 h-48 md:h-full bg-gray-200"
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            transform={`translate(${crop.x}px, ${
              crop.y
            }px) rotate(${rotation}deg) scale(${zoom}) scaleX(${
              flipHorizontal ? -1 : 1
            }) scaleY(${flipVertical ? -1 : 1})`}
          />
        </div>

        <div
          className={`w-full md:w-3/12 md:mx-2 bg-[#FAFAFA] px-2 border rounded-lg border-dashed border-light-purple text-base text-catelina-blue flex flex-col overflow-auto pb-4 md:mt-0 md:mb-0 mt-4 mb-4`}
        >
          <span className="font-semibold text-sm text-catelina-blue mb-4">
            Image Editing Options
          </span>
          <div className="w-full flex flex-col sm:flex-row mb-4">
            <div className="w-full flex flex-col">
              <label className="block text-sm font-medium text-catelina-blue">
                Crop ratio
              </label>
              <select
                id="aspect-ratio"
                className="block w-full p-1 text-xsm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={aspectRatio}
                onChange={(event) => {
                  setAspectRatio(event.target.value);
                }}
              >
                <option value={1 / 1}>1:1</option>
                <option value={4 / 3}>4:3</option>
                <option value={3 / 2}>3:2</option>
                <option value={16 / 9}>16:9</option>
                <option value={5 / 4}>5:4</option>
                <option value={2 / 1}>2:1</option>
                <option value={16 / 10}>16:10</option>
              </select>
            </div>
          </div>
          <div className="w-full flex flex-col mb-1">
            <label className="block text-sm font-medium text-catelina-blue">
              Rotate & Flip
            </label>
            <div className="flex flex-row justify-between">
              <img
                src={require("../../images/rotate-left_.svg")}
                className="hover:cursor-pointer hover:shadow-xl"
                onClick={() => {
                  setRotation(rotation + 90);
                }}
              />
              <span>|</span>
              <img
                src={require("../../images/rotate-right.svg")}
                className="hover:cursor-pointer hover:shadow-xl"
                onClick={() => {
                  setRotation(rotation - 90);
                }}
              />
              <span>|</span>
              <img
                src={require("../../images/flip_right.svg")}
                className="hover:cursor-pointer hover:shadow-xl"
                onClick={handleFlipHorizontal}
              />
              <span>|</span>
              <img
                src={require("../../images/flip_left.svg")}
                className="hover:cursor-pointer hover:shadow-xl"
                onClick={handleFlipVertical}
              />
            </div>
          </div>
          <div className="mt-2 w-full px-2">
            <span className="font-medium text-sm">Zoom</span>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              style={{
                '& .MuiSlider-thumb' : {
                  transform: 'translate(-50%, -50%)',
                }
              }}
            />
            {/* <label className="block text-center mt-2">Zoom</label> */}
          </div>
          <div className="flex flex-col">
            <Button
              className="py-1 mt-6"
              component="label"
              size="small"
              variant="contained"
              tabIndex={-1}
              disabled={isImageLoading}
              sx={{
                textTransform: "none",
                fontSize: 14,
                paddingRight: 4,
                paddingLeft: 4,
              }}
              // startIcon={<EditNoteOutlinedIcon />}
              onClick={showCroppedImage}
            >
              Save
            </Button>

            <Button
              className="py-1 mt-2"
              component="label"
              size="small"
              // color="error"
              variant="outlined"
              tabIndex={-1}
              sx={{
                textTransform: "none",
                fontSize: 14,
                paddingRight: 0,
                paddingLeft: 0,
              }}
              startIcon={<SettingsBackupRestoreSharpIcon />}
              onClick={() => {
                setZoom(1);
                setRotation(0);
                setAspectRatio(1 / 1);
                setFlipHorizontal(false);
                setFlipVertical(false);
              }}
            >
              Revert to orignal
            </Button>
          </div>
        </div>
      </div>

      {/* <div className="w-full flex flex-row justify-end px-2 mb-2">
        <button
          className="px-4 py-1 bg-grey-primary/75 text-white rounded hover:bg-grey-primary mx-2"
          onClick={onClose}
        >
          Close
        </button>

        <button
          className="px-4 py-1 bg-catelina-blue/75 text-white rounded hover:bg-catelina-blue"
          onClick={showCroppedImage}
        >
          Save
        </button>
      </div>
       */}
    </div>
  );
};

export default ImageCropper;
