import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import Login from "../components/Auth/Login";
import GoogleAnalytics from "../components/GoogleAnalytics";

export const LoginPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookie, setCookie, removeCookie] = useCookies(["rp_user_data"]);
  useEffect(() => {
    // console.log('env API_ENDPOINT', process.env.API_ENDPOINT)
    // console.log('env LINKEDIN_CLIENT_ID', process.env.LINKEDIN_CLIENT_ID)
    // console.log('env LINKEDIN_REDIRECT_URI', process.env.LINKEDIN_REDIRECT_URI)
    // console.log('env GOOGLE_CLEINT_ID', process.env.GOOGLE_CLEINT_ID)
    // console.log('env STRIPE_KEY', process.env.STRIPE_KEY)
    if(location.state && location.state.removeCookies){
      removeCookie("rp_user_data")
    }else if(cookie.rp_user_data){
      navigate("/dashboard");
    }

    // console.log('LoginPage -> props.removeCookies',location.state)
  },[])
  return (
    <>
      <Helmet>
        <title>RocketPages - Login</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {/* Include Google Analytics */}
      <GoogleAnalytics />
      <Login
        // onGoogleSignInClick={() => {
        //   console.log("onGoogleSignInClick");
        // }}
        // onFacebookSignInClick={() => {
        //   console.log("onFacebookSignInClick");
        // }}
        // onLinkedInSignInClick={() => {
        //   console.log("onLinkedInSignInClick");
        // }}
      />

    </>
  );
};
