import Button from "@mui/material/Button";
import * as React from "react";
import axiosInstance from "../../axiosInstance";
import moment from "moment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

const ConfigContactForm = (props: any) => {
  const [data, setData] = React.useState<any[]>([]);
  const [subscriptions, setSubscriptions] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fromDate, setFromDate] = React.useState<string>("");
  const [toDate, setToDate] = React.useState<string>("");
  const [allData, setAllData] = React.useState<boolean>(false);
  const [expandedMonth, setExpandedMonth] = React.useState<string | null>(null); // Accordion state

  // Fetch data from the API
  async function fetchData(
    siteId: string,
    fromDate?: string,
    toDate?: string,
    allData?: boolean
  ) {
    setLoading(true);
    try {
      let queryParams = `?siteId=${siteId}`;
      if (!allData) {
        if (fromDate) queryParams += `&fromDate=${fromDate}`;
        if (toDate) queryParams += `&toDate=${toDate}`;
      }

      let response = await axiosInstance.get(
        `/api/all-form-submissions${queryParams}`
      );
      setData(response.data.data);
      setSubscriptions(response.data.subsDetails);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchData(props.siteId);
  }, [props.siteId]);

  // Handle date change and API call
  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setFromDate(date);
    fetchData(props.siteId, date, toDate, allData); // Fetch with the new fromDate
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setToDate(date);
    fetchData(props.siteId, fromDate, date, allData); // Fetch with the new toDate
  };

  // Handle checkbox change and API call
  const handleAllDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAllData(checked);
    fetchData(props.siteId, fromDate, toDate, checked); // Fetch with "all" data if checked
  };

  // Toggle accordion
  const toggleMonth = (month: string) => {
    setExpandedMonth((prev) => (prev === month ? null : month));
  };

  // Group data by month
  const groupedData = data.reduce((acc, item) => {
    const month = moment(item.created_at_dt).format("MMMM YYYY");
    if (!acc[month]) acc[month] = [];
    acc[month].push(item);
    return acc;
  }, {});

  return (
    <div className="flex flex-col text-black h-screen flex-1 md:mr-4 mt-2.5 md:mt-0">
      {/* <div className="flex justify-end items-center space-x-2 mb-4">
        <input
          type="checkbox"
          id="allData"
          name="allData"
          checked={allData}
          onChange={handleAllDataChange}
        />
        <label htmlFor="allData">All Or</label>
        <input
          type="date"
          id="fromDate"
          name="fromDate"
          value={fromDate}
          onChange={handleFromDateChange}
          disabled={allData} // Disable date input if "All" is checked
        />
        <span>-</span>
        <input
          type="date"
          id="toDate"
          name="toDate"
          value={toDate}
          onChange={handleToDateChange}
          disabled={allData} // Disable date input if "All" is checked
        />
      </div> */}

      <span className="text-base-2 mb-2">
        {loading ? "Loading..." : `${data.length} Response(s)`}
      </span>

      {!loading && data.filter((ll) => ll.isBlur).length > 0 && (
        <div className="flex flex-col md:flex-row justify-between items-center mt-3 mb-5 gap-4 leading-5">
          <span className="text-base-3 text-red-500 relative pl-6">
            <ErrorOutlineIcon
              fontSize="small"
              sx={{
                color: "red",
                left: "0px",
                position: "absolute",
                top: "2px",
              }}
            />
            {`You have reached the limit of ${subscriptions.form_submission} contact form submissions on the ${subscriptions.name}. Upgrade to view all submissions.`}
          </span>
          <Button
            size="small"
            className="h-8 pr-8 pl-8 mt-2 md:mt-0 whitespace-nowrap"
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            onClick={() => {
              let _url = `/site-settings/${props.siteId}?section=Subscriptions`;
              window.open(_url, "_self")?.focus();
            }}
          >
            View Plans
          </Button>
        </div>
      )}

      {Object.keys(groupedData).map((month) => {
        const hasBlurredRows = groupedData[month].some((row) => row.isBlur);
        let displayedBlurMessage = false; // Flag to track if the message has been displayed
        const formattedMonth = moment(month, "MMMM YYYY").format("MMMM, YYYY");
        let blurredRowsCount = 0;
        return (
          <div key={month} className="mb-2">
            <div
              className="cursor-pointer bg-gray-100 py-2 px-4 font-bold border border-gray-300 flex items-center justify-between"
              onClick={() => toggleMonth(month)}
            >
              <span className="flex-grow">
                {formattedMonth} ({groupedData[month].length} responses)
              </span>
              {expandedMonth === month ? (
                <ExpandLessIcon className="ml-auto text-gray-500" />
              ) : (
                <ExpandMoreIcon className="ml-auto text-gray-500" />
              )}
            </div>
            <div className="overflow-x-auto max-h-[400px]">
              {expandedMonth === month && (
                <table className="relative xl:w-full border-collapse font-body rounded bg-white table-fixed mb-0 submissionTable">
                  <thead className="sticky top-0 bg-white z-10">
                    <tr>
                      <th className="text-left w-1/6 px-2 py-1 border text-base">
                        Submitted
                      </th>
                      <th className="text-left w-1/6 px-2 py-1 border text-base">
                        First Name
                      </th>
                      <th className="text-left w-1/6 px-2 py-1 border text-base">
                        Last Name
                      </th>
                      <th className="text-left w-1/6 px-2 py-1 border text-base">
                        Email
                      </th>
                      <th className="text-left w-1/6 px-2 py-1 border text-base">
                        Phone Number
                      </th>
                      <th className="text-left w-1/6 min-w-[300px] px-2 py-1 border text-base">
                        Message
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedData[month].map((row: any) => {
                      const isBlurred = row.isBlur;
                      const isFirstBlurredRow =
                        isBlurred && !displayedBlurMessage;

                      // Skip rendering more than 5 blurred rows
                      if (isBlurred && blurredRowsCount >= 5) {
                        return null; // Stop rendering if we have already displayed 5 blurred rows
                      }

                      // Increment the count if it's a blurred row
                      if (isBlurred) {
                        blurredRowsCount++;
                      }

                      if (isFirstBlurredRow) {
                        displayedBlurMessage = true; // Set the flag to true after displaying the message
                      }

                      return (
                        <React.Fragment key={row.id}>
                          {/* Show the blur message only before the first blurred row */}
                          {isFirstBlurredRow && (
                            <tr className="absolute bottom-0 left-0 right-0">
                              <td
                                colSpan={6}
                                className="absolute bottom-0 left-0 right-0 py-8"
                              >
                                <div className="w-[400px] mx-auto text-center bg-white relative border border-red-400 text-red-600 px-4 pb-2 pt-4 flex flex-col items-center justify-center seeResponse">
                                  <InfoOutlined className="mr-2 text-red-600" />
                                  <span className="mb-3 text-base leading-6">
                                    Upgrade to see the rest{" "}
                                    {groupedData[month].length -
                                      subscriptions.form_submission}
                                    {groupedData[month].length -
                                      subscriptions.form_submission >
                                    1 ? (
                                      <span> responses</span>
                                    ) : (
                                      <span> response</span>
                                    )}
                                    .
                                  </span>
                                  <Button
                                    size="small"
                                    className="h-8 pr-4 pl-4 mt-2 md:mt-0 whitespace-nowrap"
                                    variant="contained"
                                    sx={{
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      let _url = `/site-settings/${props.siteId}?section=Subscriptions`;
                                      window.open(_url, "_self")?.focus();
                                    }}
                                  >
                                    View Plans
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr
                            className={`${
                              isBlurred ? "relative isBlurred" : ""
                            }`}
                          >
                            <td
                              className={`text-left px-2 py-3 border text-xsm break-words leading-3 ${
                                isBlurred ? "blur-sm text-gray-400" : ""
                              }`}
                            >
                              {moment(row.created_at_dt).format(
                                "HH:mm:ss, DD-MM-YYYY"
                              )}
                            </td>
                            <td
                              className={`text-left px-2 py-3 border text-xsm break-words leading-3 ${
                                isBlurred ? "blur-sm text-gray-400" : ""
                              }`}
                            >
                              {row?.firstName}
                            </td>
                            <td
                              className={`text-left px-2 py-3 border text-xsm break-words leading-3 ${
                                isBlurred ? "blur-sm text-gray-400" : ""
                              }`}
                            >
                              {row?.lastName}
                            </td>
                            <td
                              className={`text-left px-2 py-3 border text-xsm break-words leading-3 ${
                                isBlurred ? "blur-sm text-gray-400" : ""
                              }`}
                            >
                              {row?.email}
                            </td>
                            <td
                              className={`text-left px-2 py-3 border text-xsm break-words leading-3 ${
                                isBlurred ? "blur-sm text-gray-400" : ""
                              }`}
                            >
                              {row?.phoneNumber}
                            </td>
                            <td
                              className={`text-left px-2 py-3 border text-xsm break-words leading-3 ${
                                isBlurred ? "blur-sm text-gray-400" : ""
                              }`}
                            >
                              {row?.message}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                    {/* Add placeholder rows if fewer than 5 blurred rows */}
                    {hasBlurredRows &&
                      Array.from({ length: 5 - blurredRowsCount }).map(
                        (_, index) => (
                          <tr
                            key={`placeholder-${index}`}
                            className="relative isBlurred"
                          >
                            {" "}
                            {/* Empty row to maintain equal spacing */}
                            <td className="text-left px-2 py-3 border text-xsm break-words leading-3 blur-sm text-gray-400">
                              &nbsp;
                            </td>
                            <td className="text-left px-2 py-3 border text-xsm break-words leading-3 blur-sm text-gray-400">
                              &nbsp;
                            </td>
                            <td className="text-left px-2 py-3 border text-xsm break-words leading-3 blur-sm text-gray-400">
                              &nbsp;
                            </td>
                            <td className="text-left px-2 py-3 border text-xsm break-words leading-3 blur-sm text-gray-400">
                              &nbsp;
                            </td>
                            <td className="text-left px-2 py-3 border text-xsm break-words leading-3 blur-sm text-gray-400">
                              &nbsp;
                            </td>
                            <td className="text-left px-2 py-3 border text-xsm break-words leading-3 blur-sm text-gray-400">
                              &nbsp;
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConfigContactForm;
