import React from "react";
import { Helmet } from "react-helmet";

const GoogleAnalytics = () => {
  const trackingId = process.env.GA_ANALYTICS_APP;

  if (!trackingId) {
    console.warn("Google Analytics tracking ID is missing.");
    return null;
  }

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingId}');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
