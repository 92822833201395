import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Navbar } from "../components/Layout/Navbar";
import NoSiteExists from "../components/Dashboard/NoSiteExists";
import SiteItem from "../components/Dashboard/SiteItem";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axiosInstance from "../axiosInstance";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import GoogleAnalytics from "../components/GoogleAnalytics";

export const DashboardPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [sitesData, setSitesData] = useState<any[]>([]);
  const [cookie_userData, setCookie_userData, removeCookie_userData] =
    useCookies(["rp_user_data"]);

  async function fetchData() {
    // let user: any = localStorage.getItem("userData") || "";
    // console.log('fetchData', ,cookie_userData.rp_user_data)
    setIsLoading(true);
    let user: any =
      cookie_userData.rp_user_data ||
      (location.state && location.state.user.data);
    // user = JSON.parse(user);
    if (user) {
      let response = await axiosInstance.get(
        `/api/configs/users-site-configs?userId=${user.userId}`
      );
      setSitesData(response.data.data);
      // console.log("response", response.data.data);
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const onCreateNewWebsite = () => {
    navigate(`/choose-template`);
  };

  const onSiteRename = async (siteId, newSiteName) => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/api/configs/append-site-config`, {
        siteId: siteId,
        siteName: newSiteName,
      });

      fetchData();
    } catch (e) {
      console.error("onSiteRename", e);
    } finally {
      setIsLoading(false);
    }
  };
  const onSiteDelete = async (siteId) => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/api/configs/delete-site`, { siteId: siteId });
      enqueueSnackbar("Site deleted successfully!", {
        variant: "info",
        className: "bg-grey-400",
      });
      fetchData();
    } catch (e) {
      console.error("onSiteDelete", e);
    } finally {
      setIsLoading(false);
    }
  };
  const onSiteDuplicate = async (siteId) => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/api/configs/duplicate-site`, {
        siteId: siteId,
      });
      enqueueSnackbar("Site duplicated successfully!", {
        variant: "success",
        className: "bg-grey-400",
      });

      fetchData();
    } catch (e) {
      console.error("onSiteDuplicate", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>RocketPages - Dashboard</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {/* Include Google Analytics */}
      <GoogleAnalytics />
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Navbar {...stateData} title={"My websites"} />
        <div className="bg-gray-100 flex absolute flex-col top-[64px] w-full">
          <div className="w-full">
            {sitesData.length > 0 && (
              <div className="py-3 px-4 flex md:justify-end">
                <Button
                  size="small"
                  variant="contained"
                  sx={{ backgroundColor: "#243060", textTransform: "none" }}
                  startIcon={<AddCircleIcon sx={{ color: "#fff" }} />}
                  onClick={onCreateNewWebsite}
                >
                  Create new website
                </Button>
              </div>
            )}
            <div className="bg-gray-100 min-h-screen">
              {sitesData.length === 0 && (
                <NoSiteExists onNewWebsiteClick={onCreateNewWebsite} />
              )}
              {/* <NoSiteExists /> */}
              {sitesData.length > 0 && (
                <div className="px-4 grid grid-cols-1 gap-8 py-4 md:grid-cols-2 lg:grid-cols-3">
                  {sitesData.map((item) => {
                    return (
                      <SiteItem
                        key={item.siteId}
                        data={item}
                        sitesData={sitesData}
                        onSettingClick={() => {
                          navigate(`/site-settings/${item.siteId}`);
                        }}
                        onEditSite={() => {
                          window.location.href = `${process.env.EDITOR_ENDPOINT}/${item.siteId}/`;
                          // window.location.reload();
                        }}
                        onSiteRename={(siteId, newSiteName) => {
                          onSiteRename(siteId, newSiteName);
                          // console.log(`Rename ${siteId} to ${newSiteName}`);
                        }}
                        onSiteDelete={(siteId) => {
                          onSiteDelete(siteId);
                          // console.log(`delete ${siteId}`);
                        }}
                        onSiteDuplicate={(siteId) => {
                          onSiteDuplicate(siteId);
                          // console.log(`duplicate ${siteId}`);
                        }}
                        // onMoreOptionClick={() => {}}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CookieConsent />
    </>
  );
};
