import * as React from 'react';
import { useState } from "react";
import { Helmet } from "react-helmet";
import ForgotPassword from "../components/Auth/ForgotPassword";
import GoogleAnalytics from "../components/GoogleAnalytics";

export const ForgotPasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>RocketPages - Forgot Password</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {/* Include Google Analytics */}
      <GoogleAnalytics />
      <ForgotPassword />
    </>
  );
};
