import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Navbar } from "../components/Layout/Navbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import Footer from "../components/Auth/Footer";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import "../styles/editor.css";
import GoogleAnalytics from "../components/GoogleAnalytics";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [cookie] = useCookies(["rp_user_data"]);
  const location = useLocation();
  const stateData = location.state;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let l_msgType = query.get("msgType");
  let actionInfos: any;
  if (l_msgType === "SiteNotFound") {
    actionInfos = {
      message:
        "Oops! This site doesn't exist. Go back to the homepage to explore.",
      actionButtomText: "Back to homepage",
      actionButtonUrl: "/dashboard",
    };
  } else if (l_msgType === "SiteNotAuthorizedToAccess" && cookie.rp_user_data) {
    actionInfos = {
      message: "Access Denied. Check your dashboard for your websites.",
      actionButtomText: "Go to Dashboard",
      actionButtonUrl: "/dashboard",
    };
  } else if (
    l_msgType === "SiteNotAuthorizedToAccess" &&
    !cookie.rp_user_data
  ) {
    actionInfos = {
      message: "Login Required. Log in or sign up to access this page.",
      actionButtomText: "Log In or Sign Up",
      actionButtonUrl: "/login",
    };
  } else if (l_msgType === "UnAuthorizedUser") {
    actionInfos = {
      message: "Private Content. Log in or sign up to access this page.",
      actionButtomText: "Log In or Sign Up",
      actionButtonUrl: "/login",
    };
  } else if (l_msgType === "UnAuthorizedAdmin") {
    actionInfos = {
      message: "Admin Content. Log in as admin to access this page.",
      actionButtomText: "Go to Dashboard",
      actionButtonUrl: "/dashboard",
    };
  } else {
    actionInfos = {
      message:
        "Oops! This page doesn't exist. Go back to the homepage to explore.",
      actionButtomText: "Back to homepage",
      actionButtonUrl: "/dashboard",
    };
  }

  return (
    <>
      <Helmet>
        <title>RocketPages - Choose Template</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {/* Include Google Analytics */}
      <GoogleAnalytics />
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Navbar {...stateData} skipCheckCookies={true} />
        <div className="fixed w-full h-screen flex justify-center items-center text-center page404">
          <div className="flex items-center flex-col bg-white w-full -mt-12 px-8">
            <h1 className="m-0 text-catelina-blue tracking-widest">404</h1>
            <p className="text-catelina-blue mt-1 text-lg">
              {actionInfos.message}
            </p>
            <button
              className="mt-0 bg-transparent hover:bg-catelina-blue text-catelina-blue font-medium py-2 px-4 border border-catelina-blue hover:border-catelina-blue hover:text-white rounded"
              onClick={() => {
                navigate(actionInfos.actionButtonUrl);
              }}
            >
              {actionInfos.actionButtomText}
            </button>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full">
        <Footer />
      </div>
    </>
  );
};

export default NotFoundPage;
